.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
  font-family: Arial, sans-serif;
}

.login-container h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #333;
}

.login-container button {
  padding: 10px 20px;
  font-size: 1em;
  color: white;
  background-color: #0078d4;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-container button:hover {
  background-color: #005a9e;
}
