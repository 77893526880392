/* Base styling */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/* Navbar styling */
.navbar {
  display: flex;
  justify-content: center;
  background-color: #333;
  padding: 10px 0;
}

.nav-links {
  display: flex;
  gap: 20px;
}

.nav-link {
  color: white;
  text-decoration: none;
  margin: 0 15px;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.nav-link:hover {
  background-color: #555;
}

.sign-out-button {
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.sign-out-button:hover {
  background-color: #d32f2f;
}

/* Styling for UserInput component */
.csv-form {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.csv-header {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.form-group label {
  flex: 1 1 calc(50% - 20px);
  display: flex;
  flex-direction: column;
}

.csv-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.csv-table th,
.csv-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.csv-table th {
  background-color: #f2f2f2;
}

.csv-table input[type="text"],
.csv-table input[type="number"],
.csv-table select {
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  margin: 0;
}

.csv-table button {
  padding: 5px 10px;
  margin: 0;
}

/* Modal styling for Approvals */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1100;
  max-width: 90vw;
  max-height: 90vh;
  overflow: auto;
}

.approval-dialog {
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: auto;
  white-space: nowrap;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
}

.approve-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.approve-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.approve-buttons button:first-of-type {
  background-color: #4caf50;
  color: white;
}

.approve-buttons button:last-of-type {
  background-color: #f44336;
  color: white;
}

/* Dashboard component styling */
.dashboard-container {
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: white;
  box-sizing: border-box;
}

.dashboard-container h2 {
  margin-bottom: 20px;
}

.table-container {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

table th,
table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

#viewcostSheetTable table {
  width: 100%;
  table-layout: fixed;
  word-wrap: break-word;
  word-break: break-word;
  border-collapse: collapse; 
}


#viewcostSheetTable td, th {
  border: 1px solid #000; 
  padding: 8px; 
  text-align: left; 
  white-space: normal;
}
#viewcostSheetTable tr{
  border: 1px solid #000;
}
#viewcostSheetTable tbody, td, tfoot, th, thead, tr{
  border-color: #000 !important;
  border: 1px solid !important;
}


.search-container {
  margin-bottom: 20px;
}

.metrics-container {
  margin: 20px 0;
  display: flex;
  align-items: center;
}

.metrics-container label {
  margin-right: 10px;
  font-weight: bold;
  font-size: 1.2em;
  color: #333;
}

.metrics-container select {
  padding: 5px 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  transition: border-color 0.3s;
}

.metrics-container select:focus {
  border-color: #007bff;
}

.metrics {
  margin-top: 10px;
}

.metric {
  margin: 5px 0;
}

.metric-key {
  font-weight: bold;
  color: #555;
}

.metric-value {
  color: #888;
}

.highlight-on-hover:hover {
  background-color: #f0f0f0;
}
.approved {
  background-color: #4CAF50; 
  color: white;
  text-align: center;
}

.rejected {
  background-color: #F44336; 
  color: white;
  text-align: center;
}

.metrics-grid {
  display: flex;
    /* grid-template-columns: 1fr 1fr; */
    gap: 1rem;
    align-content: center;
    flex-wrap: wrap;
    overflow: overlay;
}

.metrics-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  background-color: #f9f9f9;
}

.metrics-key {
  font-weight: bold;
  font-size: 1.2rem;
}

.metrics-value {
  font-size: 1rem;
  margin: 0px 15px ;
  background-color: #555;
  color:white;
  padding: 0px 5px;
}

.metrics-item:hover {
  background-color: #f1f1f1;
}
.card-body {
  padding: 1.5rem;
}
#dashboardTable tr{
  background-color: #007bff;
}

.custom-modal .modal-content{
  margin-top: 200px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.form-control{
  padding: 4px !important;
}

.fs-14{
  font-size: 15px;
}

/*print css */
@media print {
  #viewcostSheetTable {
    /* width: 100% !important;   */
    table-layout: auto !important;  
  }
  .table-responsive {
    overflow-x: visible !important;
  }
  @page {
    size: landscape;  
    margin: 1cm; 
  }
  body {
    font-size: 12px;
  }
  .no-print {
    display: none;
  }
}

#viewcostSheetTable table, th, td{
  text-overflow: ellipsis;
  overflow: hidden;
}