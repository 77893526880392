/* styles.css */

body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f9;
  margin: 0;
  padding: 0;
}

.csv-form {
  width: 90%;
  max-width: 100%;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.form-group label {
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group input[type="number"],
.form-group input[type="date"],
.form-group select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.item-section {
  border-top: 1px solid #ddd;
  padding-top: 15px;
  margin-top: 15px;
}

.item-header {
  font-weight: bold;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.item-fields {
  display: flex;
  flex-wrap: wrap;
}

.item-fields label {
  flex: 1 1 calc(33.33% - 20px);
  margin-right: 20px;
  margin-bottom: 10px;
}

.item-fields input[type="number"],
.item-fields input[type="text"] {
  width: 100%;
}

.item-fields button {
  flex: 1 1 100%;
  padding: 10px;
  border: none;
  background-color: #dc3545;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 10px;
}

.item-fields button:hover {
  background-color: #c82333;
}

button[type="button"] {
  padding: 10px 20px;
  margin-top: 20px;
  border: none;
  /* background-color: #28a745; */
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
}

button[type="button"]:hover {
  /* background-color: #218838; */
}

button[type="submit"] {
  padding: 10px 20px;
  margin-top: 20px;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
}

button[type="submit"]:hover {
  background-color: #0069d9;
}

input[type="text"],
input[type="number"],
input[type="date"],
select {
  transition: all 0.3s ease;
}

input[type="text"]:focus,
input[type="number"]:focus,
input[type="date"]:focus,
select:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.metrics-container {
  display: flex;
  align-items: center;
}

.metrics-container select {
  margin-right: 10px;
  flex: 1;
}

.metrics-container input {
  flex: 2;
}

.span-style {
  text-align: center; /* Horizontally center the text */
  vertical-align: middle; /* Vertically center the text */
  height: 100%; /* Ensure the cell takes the full height */
}
